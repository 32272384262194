import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'

class FollowUpsReducers {
  getUserFollowUps (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          results: [],
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          results: _.get(action, 'result')
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

  getUserFollowUpCount (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          count: _.get(action, 'result.count', 0)
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

  updateReportName(state, action) {
    const { extReportId, name } = action.payload
    return {
      ...state,
      results: _.map(state.results, followUp =>
        followUp.report.extReportId === extReportId
          ? {
              ...followUp,
              report: {
                ...followUp.report,
                userReportConfig: [{
                  ...followUp.report.userReportConfig?.[0],
                  friendlyName: name
                }]
              }
            }
          : followUp
      )
    }
  }
}

export default new FollowUpsReducers()
