import * as constants from './constants'
import reducers from './reducers'
import * as reportConstants from '../reports/constants'

const defaultState = {
  error: undefined,
  status: undefined,
  results: [],
  count: 0
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.GET_USER_FOLLOWUPS:
      return reducers.getUserFollowUps(state, action)
    case constants.GET_USER_FOLLOWUP_COUNT:
      return reducers.getUserFollowUpCount(state, action)
    case reportConstants.RENAME_REPORT:
      return reducers.updateReportName(state, action)
    case reportConstants.REMOVE_REPORT_NAME:
      return reducers.updateReportName(state, { ...action, payload: { ...action.payload, name: null } })
    default:
      return state
  }
}
