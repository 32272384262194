import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'

class ReportsReducers {
  searchReportsFresh (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          results: [],
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        const { query, storeIds, dateFrom, dateTo, reportStatus, reportType, reportSource } = action
        const results = _.get(action, 'result.results')
        const total = _.get(action, 'result.total')
        return {
          ...state,
          status: SUCCESS,
          page: 1,
          total,
          results,
          query,
          storeIds,
          dateFrom,
          dateTo,
          reportStatus,
          reportType,
          reportSource
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

  searchReportsNext (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        const { results } = state
        const { query, storeIds, dateFrom, dateTo, reportStatus, reportType, reportSource } = action
        const newResults = _.get(action, 'result.results')
        const total = _.get(action, 'result.total')
        return {
          ...state,
          status: SUCCESS,
          total,
          page: state.page + 1,
          results: [ ...results, ...newResults ],
          query,
          storeIds,
          dateFrom,
          dateTo,
          reportStatus,
          reportType,
          reportSource
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

  fetchReportDetailsById (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          reportDetails: _.get(action, 'result'),
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

  changeFilter = (state, action) => {
    const { filter } = action
    const { filters } = state
    return {
      ...state,
      filters: {
        ...filters,
        [filter.name]: filter.value
      }
    }
  }

  changeFilters = (state, action) => {
    const { filters } = action
    return {
      ...state,
      filters
    }
  }

  getCasesSummary = (state, action) => {
    const { result } = action
    return {
      ...state,
      cases: {
        ...state.cases,
        summary: result
      },
    }
  }

  renameReport(state, action) {
    const { extReportId, name } = action.payload
    return {
      ...state,
      results: _.map(state.results, report => 
        report.extReportId === extReportId
          ? {
              ...report,
              userReportConfig: [{
                ...report.userReportConfig?.[0],
                friendlyName: name
              }]
            }
          : report
      )
    }
  }

  removeReportName(state, action) {
    const { extReportId } = action.payload
    return {
      ...state,
      results: _.map(state.results, report =>
        report.extReportId === extReportId
          ? {
              ...report,
              userReportConfig: [{
                ...report.userReportConfig?.[0],
                friendlyName: null
              }]
            }
          : report
      )
    }
  }
}

export default new ReportsReducers()
