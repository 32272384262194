export const SEARCH_REPORTS_FRESH = 'reports/SEARCH_REPORTS_FRESH'
export const SEARCH_REPORTS_NEXT = 'reports/SEEARCH_REPORTS_NEXT'
export const RESET_STORE = 'reports/RESET_STORE'
export const CHANGE_REPORTS_FILTER = 'reports/CHANGE_REPORTS_FILTER'
export const CHANGE_REPORTS_FILTERS = 'reports/CHANGE_REPORTS_FILTERS'
export const FETCH_REPORT_DETAILS_BY_ID = 'reports/FETCH_REPORT_DETAILS_BY_ID'
export const FETCH_REPORT_FOR_ID = 'reports/FETCH_REPORT_FOR_ID'
export const DELETE_REPORT_BY_ID = 'reports/DELETE_REPORT_BY_ID'
export const ASSIGN_REPORT = 'reports/ASSIGN_REPORT'
export const FETCH_REPORT_ASSIGNMENT_TOKEN = 'reports/FETCH_REPORT_ASSIGNMENT_TOKEN'
export const FETCH_CASE_SUMMARY = 'reports/FETCH_CASE_SUMMARY'
export const FETCH_REPORT_ASSIGNMENT_TOKEN_SSR = 'reports/FETCH_REPORT_ASSIGNMENT_TOKEN_SSR'
export const RENAME_REPORT = 'reports/RENAME_REPORT'
export const REMOVE_REPORT_NAME = 'reports/REMOVE_REPORT_NAME'
