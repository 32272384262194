import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  size: 10,
  page: 1,
  error: undefined,
  query: undefined,
  total: undefined,
  status: undefined,
  results: [],
  filters: {
    extReportId: 'all'
  },
  cases: {
    summary: []
  }
}
export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.SEARCH_REPORTS_FRESH:
      return reducers.searchReportsFresh(state, action)
    case constants.SEARCH_REPORTS_NEXT:
      return reducers.searchReportsNext(state, action)
    case constants.CHANGE_REPORTS_FILTER:
      return reducers.changeFilter(state, action)
    case constants.CHANGE_REPORTS_FILTERS:
      return reducers.changeFilters(state, action)
    case constants.FETCH_REPORT_DETAILS_BY_ID:
      return reducers.fetchReportDetailsById(state, action)
    case constants.FETCH_CASE_SUMMARY:
      return reducers.getCasesSummary(state, action)
    case constants.RENAME_REPORT:
      return reducers.renameReport(state, action)
    case constants.REMOVE_REPORT_NAME:
      return reducers.removeReportName(state, action)
    case constants.FETCH_REPORT_FOR_ID:
      return state
    default:
      return state
  }
}
